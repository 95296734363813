import React, { useState } from "react";

import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Box, Stack, Typography } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import { ROLES } from "../../helpers/constants";
import DownloadCSV from "../exportCSV/downloadCSV";
import { decryptPayload, getObjLength } from "../../helpers/utils";
import AdminFreeMonitoringListTable from "./AdminFreeMonitoringListTable";
import { exportCSVDetialsAction } from "../../../redux/slices/export-csv/exportcsvSlice";

const AdminFreeMonitoring = () => {
  const [propertiesIDList, setPropertiesIDList] = useState([]);
  const [deletePropertyModal, setDeletePropertyModal] = useState(false);
  const [selectAllFlag, setSelectAllFlag] = useState(false);
  const [csv_Payload, setCSV_Payload] = useState([]);

  const dispatch = useDispatch();

  const handleDeleteProperty = (action) => {
    setDeletePropertyModal(true);
  };
  let county_admin = decryptPayload(localStorage.getItem("roles"))?.includes(
    ROLES.countyRecorder
  );

  const { exportCsvLoader, exportCSVFileData } = useSelector(
    (state) => state.exportCSVDetails
  );

  const handleExportCSV = () => {
    console.log("payload-->", csv_Payload);
    dispatch(
      exportCSVDetialsAction.exportCSVFile({
        url: "export-property-monitoring-csv",
        data: csv_Payload,
        token: true,
      })
    );
  };

  const handleCloseDownloadModal = (action) => {
    dispatch(exportCSVDetialsAction.getExportCSVFile({}));
  };

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6" className="title">
          Property Monitoring
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box className="">
            <Button
              variant="contained"
              className="success-btn d-none d-sm-block mx-2"
              style={{
                padding: "10px 30px",
                borderRadius: "20px",
              }}
              onClick={() => {
                handleExportCSV();
              }}
            >
              Export CSV
            </Button>
            <Button
              className="success-btn d-flex d-sm-none"
              variant="outlined"
              onClick={() => {
                handleExportCSV();
              }}
              startIcon={<IosShareIcon />}
              sx={{ color: "#FFF" }}
            >
              Export
            </Button>
          </Box>
          {!county_admin && propertiesIDList?.length > 0 && (
            <Button
              variant="contained"
              className="btn d-none d-sm-block popup_Btn"
              style={{ backgroundColor: "#f43455", borderRadius: "25px" }}
              onClick={() => {
                console.log("On delete click");
                handleDeleteProperty();
              }}
            >
              Delete Properties
            </Button>
          )}
        </Stack>
      </Stack>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <AdminFreeMonitoringListTable
          setPropertiesIDList={setPropertiesIDList}
          propertiesIDList={propertiesIDList}
          setDeletePropertyModal={setDeletePropertyModal}
          deletePropertyModal={deletePropertyModal}
          handleDeleteProperty={handleDeleteProperty}
          selectAllFlag={selectAllFlag}
          setSelectAllFlag={setSelectAllFlag}
          setCSV_Payload={setCSV_Payload}
        />
      </Box>

      {/* Export CSV */}
      {getObjLength(exportCSVFileData) && (
        <DialogBox
          width="md"
          style={{ backgroundColor: "#f6f9fc" }}
          contentClassName={""}
          openPopup={true}
          onClose={() => {
            dispatch(exportCSVDetialsAction.clearData({}));
          }}
          content={
            <DownloadCSV
              onClose={(action) => {
                handleCloseDownloadModal(action);
              }}
              exportCsvData={exportCSVFileData}
            />
          }
        />
      )}

      <Loader open={exportCsvLoader} />
    </>
  );
};

export default AdminFreeMonitoring;
