import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik, useFormikContext } from "formik";

import { Box, Grid, MenuItem, TextField } from "@mui/material";

import DownloadCSV from "./downloadCSV";
import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import AlertDialog from "../../common/AlertDialog";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import { getDateInYYYYMMDDFormat, getObjLength } from "../../helpers/utils";
import { exportCSVDetialsAction } from "../../../redux/slices/export-csv/exportcsvSlice";

const ExportDetailsForm = ({ goBack }) => {
  const dispatch = new useDispatch();
  const { exportCsvLoader, exportCsvData } = useSelector(
    (state) => state.exportCSVDetails
  );

  const [openDownload, setOpenDownload] = useState(false);
  const durationList = [
    // { name: "Today" },
    // { name: "Yesterday" },
    { name: "This Week" },
    { name: "This Month" },
    { name: "Last Month" },
    { name: "Last 3 Months" },
    { name: "Last 6 Months" },
    { name: "This Year" },
    { name: "Custom Date" },
  ];

  const validationSchema = Yup.object().shape({
    duration: Yup.string().required("Duration is required"),
    dateRange: Yup.mixed().when("duration", {
      is: "Custom Date",
      then: Yup.object().shape({
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date must be after start date"),
      }),
      otherwise: Yup.mixed().test(
        "is-date-range",
        "Date range is required",
        (value) => value !== null && value !== undefined
      ),
    }),
  });

  const getDateRange = (duration) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date(today);

    switch (duration) {
      case "Today":
        return { startDate: today, endDate };
      case "Yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        return { startDate: yesterday, endDate: yesterday };
      case "This Week":
        const thisWeekStart = new Date(today);
        thisWeekStart.setDate(thisWeekStart.getDate() - thisWeekStart.getDay());
        return { startDate: thisWeekStart, endDate };
      case "This Month":
        const thisMonthStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        return { startDate: thisMonthStart, endDate };
      case "Last Month":
        const lastMonthStart = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        return { startDate: lastMonthStart, endDate: lastMonthEnd };
      case "Last 3 Months":
        const threeMonthsAgo = new Date(today);
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        return { startDate: threeMonthsAgo, endDate };
      case "Last 6 Months":
        const sixMonthsAgo = new Date(today);
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        return { startDate: sixMonthsAgo, endDate };
      case "This Year":
        const thisYearStart = new Date(today.getFullYear(), 0, 1);
        return { startDate: thisYearStart, endDate };
      case "Custom Date":
        return { startDate: today, endDate };
      default:
        return null;
    }
  };
  const AutoDateRange = () => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
      //   if (values.duration !== "Custom Date") {
      const newDateRange = getDateRange(values.duration);
      setFieldValue("dateRange", newDateRange);
      //   }
    }, [values.duration, setFieldValue]);

    return null;
  };

  const handleSubmit = (from_date, to_date) => {
    let url = "admin/export-payment-detail-csv";
    const data = {
      from_date: from_date,
      to_date: to_date,
    };
    // }
    dispatch(
      exportCSVDetialsAction.setExportCSVData({
        url: url,
        data: data,
        token: true,
        isFile: true,
      })
    );
  };

  const handleCloseDownloadModal = (action) => {
    setOpenDownload(false);
    dispatch(exportCSVDetialsAction.clearData({}));
    if (action !== "Error") {
      goBack();
    }
  };

  useEffect(() => {
    if (getObjLength(exportCsvData)) {
      setOpenDownload(true);
    }
  }, [exportCsvData]);

  return (
    <Box>
      <Formik
        initialValues={{
          duration: "This Month",
          dateRange: { startDate: new Date(), endDate: new Date() },
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // if (values.duration === "Custom Date" && values?.dateRange?.startDate?._d) {
          //   let from_date = getDateInYYYYMMDDFormat(
          //     values?.dateRange?.startDate?._d,
          //     "-"
          //   );
          //   let to_date = getDateInYYYYMMDDFormat(
          //     values?.dateRange?.endDate?._d,
          //     "-"
          //   );
          //   console.log("Submitted values:", from_date, to_date);
          //   handleSubmit(from_date, to_date);
          // } else {
          let from_date = getDateInYYYYMMDDFormat(
            values?.dateRange?.startDate,
            "-"
          );
          let to_date = getDateInYYYYMMDDFormat(
            values?.dateRange?.endDate,
            "-"
          );
          console.log("Submitted values:", from_date, to_date);
          handleSubmit(from_date, to_date);
          // }
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <AutoDateRange />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  px={1}
                  pb={1}
                  sx={{ marginTop: "23px" }}
                >
                  <Field
                    as={TextField}
                    select
                    name="duration"
                    label="Duration"
                    fullWidth
                    error={touched.duration && errors.duration}
                    helperText={touched.duration && errors.duration}
                  >
                    {durationList.map((option) => (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <CustomDateRangePicker
                    rangeValue={values.dateRange}
                    onChange={(range) => {
                      const data = {
                        startDate: range.startDate._d,
                        endDate: range.endDate._d,
                      };
                      setFieldValue("dateRange", data);
                    }}
                    onClear={() => setFieldValue("dateRange", null)}
                    label="Date Range"
                    name="dateRange"
                    values={values.dateRange}
                    touched={touched.dateRange}
                    errors={touched.dateRange && Boolean(errors.dateRange)}
                    disabled={values.duration !== "Custom Date"}
                  />
                </Grid>
                <Grid xs={12} md={12} px={1} pb={1}>
                  <div className="text-center createAcBtn mt-5">
                    <Button
                      type="submit"
                      className="next_button"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      {openDownload && (
        <DialogBox
          width="md"
          style={{ backgroundColor: "#f6f9fc" }}
          contentClassName={""}
          openPopup={openDownload}
          setOpenPopup={setOpenDownload}
          onClose={() => {
            setOpenDownload(false);
            dispatch(exportCSVDetialsAction.clearData({}));
          }}
          content={
            <DownloadCSV
              onClose={(action) => {
                setOpenDownload(false);
                handleCloseDownloadModal(action);
              }}
              exportCsvData={exportCsvData}
            />
          }
        />
      )}

      <Loader open={exportCsvLoader} />
    </Box>
  );
};

export default ExportDetailsForm;
